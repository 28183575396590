:root {
  --animate-duration: 0.25s;
  --brand-primary-color: #fc017f;
  --brand-darken-primary-color: #ad0058;
  --brand-darkest-primary-color: #6e0038;
  --brand-tertiary-color: #eaf2ff;
  --brand-secondary-color: #5f718d;
  --brand-darken-secondary-color: #4b5a6f;
  --brand-darkest-secondary-color: #3a4758;
  --brand-darken-tertiary-color: #dfe7f5;
  --brand-background-color: #e7f1fa;
  --brand-success-color: #8ee295;
  --dark-section-background-color: #f6f6f6;
  --focus-border-color: #004de2;
  --rangeslider-fill-color: #707070;
  --rangeslider-handle-color: #484848;
  --text-primary-color: #414141;
  --text-secondary-color: #666666;
  --text-tertiary-color: #7588a7;
  --text-muted-color: #919191;
  --text-disabled-color: #a9a9a9;
  --inactive-form-label-color: #b4b4b4;
  --skeleton-color: #aeb4c1;
  --bs-primary-rgb: 252, 1, 127;
  --plan-pay-as-you-go-background-color: #fff7cd;
  --plan-pay-as-you-go-color: #786500;
  --plan-fixed-background-color: #e4f6e2;
  --plan-fixed-color: #71826e;
  --plan-pay-later-background-color: #ffebe5;
  --plan-pay-later-color: #ed8951;
  --link-color: #2a4e96;
  --link-active-color: #ad0058;
}

html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--text-primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  /* background: #f6fcff; */
}

a,
.link {
  text-decoration: underline;
  color: var(--link-color);
}

a:hover,
.link:hover {
  text-decoration: none;
  color: var(--brand-primary-color);
}

a:active,
.link:active {
  color: var(--link-active-color);
}

.link-white {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}

.link-white:hover {
  color: #ffffff;
  text-decoration: none;
}

.link-white:active {
  color: var(--brand-tertiary-color);
}

label {
  user-select: none;
}

select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5.6719 6L0.759883 -6.52533e-07L10.5839 -4.68497e-08L5.6719 6Z' /%3E%3C/svg%3E");
  background-position: calc(100%) calc(100% - 3px);
  background-size: 24px 24px, 24px 24px;
  background-repeat: no-repeat;
}

@-webkit-keyframes rhinoFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes rhinoFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-webkit-keyframes rhinoFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes rhinoFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rhino_animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rhino_animate__fadeIn {
  -webkit-animation-name: rhinoFadeIn;
  animation-name: rhinoFadeIn;
}

.rhino_animate__fadeOut {
  -webkit-animation-name: rhinoFadeOut;
  animation-name: rhinoFadeOut;
}

.well {
  display: flex;
  align-items: center;
}

.text-right {
  text-align: right !important;
}

.text-xlarge {
  font-size: 2rem;
}

.text-large {
  font-size: 1.2rem;
}

.text-medium {
  font-size: 0.8rem;
}

.text-small {
  font-size: 0.7rem;
}

.text-micro {
  font-size: 0.5rem;
}

.text-primary {
  color: var(--brand-primary-color);
}

.text-muted {
  color: var(--text-muted-color) !important;
}

.text-disabled {
  color: var(--text-disabled-color) !important;
}

.text-bold {
  font-weight: 600;
}

.line-height-1 {
  line-height: 1;
}

.float-right {
  float: right;
}

.w-100px {
  width: 100px;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.max-w-33 {
  max-width: 33%;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-75 {
  max-width: 75%;
}

.max-w-150px {
  max-width: 150px;
}

.max-w-180px {
  max-width: 180px;
}

.max-w-200px {
  max-width: 200px;
}

.max-w-400px {
  max-width: 400px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-600px {
  max-width: 600px;
}

.max-w-750px {
  max-width: 750px;
}

.max-w-800px {
  max-width: 800px;
}

.max-w-1100px {
  max-width: 1100px;
}

.h-100vh {
  height: 100vh;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.opacity-75 {
  opacity: 0.75;
}

.bg-brand-primary {
  background-color: var(--brand-primary-color);
}

.bg-success {
  background-color: var(--brand-success-color) !important;
}

.bg-tertiary {
  background-color: var(--brand-tertiary-color);
}

.bg-muted {
  background-color: var(--dark-section-background-color);
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.cursor-arrow {
  cursor: default !important;
  user-select: none;
}

.user-select-none {
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.font-weight-600 {
  font-weight: 600;
}

.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.aspect-ratio-3-4 {
  aspect-ratio: 3/4;
}

.aspect-ratio-4-3 {
  aspect-ratio: 4/3;
}

.btn {
  border-radius: 1.5rem;
  padding: 0.75rem;
}

.btn-primary {
  background-color: var(--brand-primary-color);
  color: #fff;
  border-color: var(--brand-primary-color);
}

.btn-primary:hover {
  background-color: var(--brand-darken-primary-color);
  border-color: var(--brand-darken-primary-color);
}

.btn-secondary {
  background-color: var(--brand-secondary-color);
  color: #fff;
  border-color: var(--brand-secondary-color);
}

.btn-secondary:hover {
  background-color: var(--brand-darken-secondary-color);
  border-color: var(--brand-darken-secondary-color);
}

.btn-tertiary {
  background-color: var(--brand-tertiary-color);
  color: var(--text-tertiary-color);
  border-color: var(--brand-tertiary-color);
}

.btn-tertiary:hover {
  background-color: var(--brand-darken-tertiary-color);
  color: var(--text-tertiary-color);
  border-color: var(--brand-darken-tertiary-color);
}

.btn-xs {
  height: 1.75rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary:active:focus {
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
  box-shadow: none;
}

.btn-outline-primary:disabled,
.btn-primary:disabled {
  opacity: 0.15;
}

.btn-outline-primary {
  color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
  background-color: unset;
}

.btn-outline-primary:hover {
  color: var(--brand-darken-primary-color);
  border-color: var(--brand-darken-primary-color);
  background-color: unset;
}

.btn-outline-primary-fill-hover:hover {
  color: #ffffff;
  border-color: var(--brand-primary-color);
  background-color: var(--brand-primary-color);
}

.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:disabled,
.btn-outline-primary:active:focus {
  color: var(--brand-darken-primary-color);
  border-color: var(--brand-darken-primary-color);
  background-color: #ffffff;
  box-shadow: none;
}

.btn-outline-secondary {
  color: var(--brand-secondary-color);
  border-color: var(--brand-secondary-color);
}

.btn-outline-secondary:disabled {
  color: var(--brand-secondary-color);
  border-color: var(--brand-secondary-color);
  opacity: 0.4;
}

.btn-outline-secondary:active {
  border-color: var(--brand-secondary-color);
  color: var(--brand-secondary-color);
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-secondary:hover {
  color: var(--brand-darken-secondary-color);
  border-color: var(--brand-darken-secondary-color);
  background-color: transparent;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:focus-visible {
  color: var(--brand-darken-secondary-color);
  border-color: var(--brand-darken-secondary-color);
  outline: none !important;
  box-shadow: none !important;
}

.inline-form-control {
  border: none;
  border-bottom: 1px solid var(--text-tertiary-color);
  color: var(--text-primary-color);
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.inline-form-control:focus-visible {
  outline: none;
  border-bottom: 1px solid var(--brand-primary-color);
}

.form-control {
  border-radius: 0.75rem;
  padding: 0.75rem;
}

.form-control-xs {
  display: unset;
  height: 1.5rem;
  font-size: 0.6rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--text-primary-color);
  padding: 0rem 0rem 0rem 0.25rem;
  width: unset;
  min-width: 2.5rem;
  background-color: #ffffff;
  background-position: calc(100% + 6px) 7px;
  background-size: 14px 14px, 14px 14px;
  padding: 0px;
  font-size: 12px;
}

.form-control-xs:hover {
  border-bottom: 1px solid var(--brand-primary-color);
}

.form-control-xs:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--focus-border-color);
}

.form-check-input:checked {
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
}

.form-check-input.noticeable {
  border-color: var(--brand-primary-color);
}

.form-check-input.muted:checked {
  background-color: var(--text-secondary-color);
  border-color: var(--text-secondary-color);
}

.form-check-input:focus,
.form-control:focus {
  box-shadow: none;
  border-color: var(--focus-border-color);
  outline: none;
}

.rounded {
  border-radius: 0.75rem !important;
}

.slightly-rounded {
  border-radius: 0.25rem !important;
}

.grayscale-0 {
  filter: grayscale(0%);
}

.grayscale-1 {
  filter: grayscale(100%);
}

.modal-backdrop-cover {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #00000060;
}

.modal-header-button {
  opacity: 0.5;
  transition: opacity 0.15s ease-in-out;
}

.modal-header-button:hover {
  opacity: 1;
}

.modal-content {
  border-radius: 0.6rem;
  border: none;
}

.modal-footer {
  border-top: none;
  background-color: var(--dark-section-background-color);
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.otp-input {
  width: 150px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 19px;
  border: none;
  background-color: var(--dark-section-background-color);
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 3px;
  border-radius: 4px;
}

.otp-input:focus {
  background-color: var(--dark-section-background-color);
}

.loading-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.loading-contained {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
}

#section-header .logo {
  height: 50px;
}

#section-header .greeting {
  height: 15px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
}

.stepper-element {
  transform: scale(0.7);
}

.stepper-counter {
  top: 0.5rem;
  right: 1rem;
}

.stepper-counter:disabled {
  opacity: 1;
  border-color: var(--text-primary-color);
  color: var(--text-primary-color);
}

#section-page h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  display: none;
}

#section-page h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 24px;
}

.section-dark {
  background-color: var(--dark-section-background-color);
}

.plan-container::-webkit-scrollbar {
  display: none;
}

.plan-container .plan {
  position: relative;
}

.swipe-animation-holder {
  width: 35%;
  position: relative;
  height: 100%;
}

.ribbon-wrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: -1px;
}

.ribbon {
  font-size: 0.7rem;
  text-align: center;
  transform: rotate(45deg);
  position: relative;
  padding: 0px 0;
  left: -10px;
  top: 15px;
  width: 130px;
  font-weight: 600;
}

.ribbon-recommended {
  background-color: var(--skeleton-color);
  color: #ffffff;
}

.ribbon-soon {
  background-color: var(--inactive-form-label-color);
  color: var(--text-primary-color);
}

.plan-container h2,
.inline-form-control {
  font-size: 30px;
  font-weight: 600;
  display: inline;
}

.plan-container h4 {
  font-size: 15px;
  font-weight: 600;
  display: inline;
}

.plan-container .feature-item {
  line-height: 1.35rem;
}

.plan-container .plan .footer.pay_as_you_go {
  background-color: var(--plan-pay-as-you-go-background-color);
  color: var(--plan-pay-as-you-go-color);
}

.plan-container .plan button.selected {
  opacity: 0.5 !important;
}

.plan-container .plan button.btn-pay_as_you_go {
  border-color: var(--plan-pay-as-you-go-color);
  color: var(--plan-pay-as-you-go-color);
  opacity: 0.9;
}

.plan-container .plan button.btn-fixed {
  border-color: var(--plan-fixed-color);
  color: var(--plan-fixed-color);
  opacity: 0.9;
}

.plan-container .plan button.btn-fixed:hover {
  opacity: 1;
}

.plan-container .plan .footer {
  width: calc(100% - 4px);
  bottom: 0;
  left: 2px;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  height: 0.5rem;
  position: absolute;
  background-color: var(--dark-section-background-color);
}

.plan-container .plan .footer.pay_as_you_go {
  background-color: var(--plan-pay-as-you-go-background-color);
}

.plan-container .plan .footer.fixed {
  background-color: var(--plan-fixed-background-color);
}

.plan-container .plan .footer.pay_now {
  background-color: var(--plan-pay-as-you-go-background-color);
}

.plan-container .plan .footer.loan,
.plan-container .plan .footer.loan12,
.plan-container .plan .footer.loan15,
.plan-container .plan .footer.loan20,
.plan-container .plan .footer.loan25 {
  background-color: #fdf1f7;
}

.skeleton-text {
  user-select: none;
  background-color: var(--skeleton-color);
  color: var(--skeleton-color);
  opacity: 0.35;
  display: inline-block;
}

.border-brand-color {
  border-color: var(--brand-primary-color) !important;
}

.border-muted {
  border-color: var(--inactive-form-label-color) !important;
}

.battery-container {
  border-radius: 0.5rem;
  display: block;
  width: 100%;
}

.battery-container h2 {
  font-size: 30px;
  font-weight: 600;
  display: inline;
}

.battery-container h4 {
  font-size: 15px;
  font-weight: 600;
  display: inline;
}

footer {
  height: 11px;
  width: 100vw;
  background-color: var(--brand-background-color);
  margin: 0;
  position: fixed;
  bottom: 0;
}

.tippy-popper,
.tippy-tooltip,
.tippy-tooltip [x-circle] {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: unset;
}

.tippy-popper[aria-hidden="true"] {
  display: none !important;
}

.tippy-box[data-theme~="light"] {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.tippy-box[data-theme~="light"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #dddddd;
  top: -8px;
}

.form-floating label {
  color: #b4b4b4;
  padding-left: 25px;
}
.form-floating input:not(:placeholder-shown) {
  color: #414141;
}

.form-floating input:not(:placeholder-shown) ~ label {
  color: #414141;
}

.form-floating select:not(:placeholder-shown) {
  color: #414141;
}

.form-floating select:not(:placeholder-shown) ~ label {
  color: #414141;
}

.form-floating .form-control:focus {
  border-color: var(--brand-primary-color);
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2rem;
  margin-top: unset;
}

/* md */
@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  #section-page h1 {
    font-size: 36px;
    display: block;
  }

  .stepper-counter {
    top: 4rem;
    right: -1rem;
  }

  footer {
    height: 22px;
  }

  .subtitle {
    font-size: 15px;
    margin-top: 1rem;
  }
}

/* lg */
@media (min-width: 992px) {
  .h-lg-100px {
    height: 100px !important;
  }

  .h-lg-200px {
    height: 200px !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-33 {
    width: 33% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-unset {
    width: unset !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .plan-container h2,
  .inline-form-control {
    font-size: 30px;
  }

  .plan-container h4 {
    font-size: 19px;
  }

  .battery-container {
    display: unset;
    width: unset;
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* xl */
@media (min-width: 1400px) {
  .w-xl-33 {
    width: 33% !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-floating > .form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-floating label {
  color: var(--inactive-form-label-color);
  padding-left: 1.5rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  opacity: 1;
  transform: scale(0.85) translateY(-1rem) translateX(2rem);
  padding: 0.25rem 0.5rem;
  height: 1.75rem;
  background-color: #ffffff !important;
  font-size: 0.82rem;
}

select:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: #ffffff !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 0.4;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--brand-darken-primary-color);
  cursor: pointer;
}

.installer-logo {
  max-width: 200px;
}

.rangeslider-horizontal {
  height: 8px;
  border-radius: 4px;
}

.rangeslider .rangeslider__handle {
  background: var(--rangeslider-handle-color);
  border: none;
  box-shadow: none;
  width: 18px;
  height: 18px;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: var(--rangeslider-fill-color);
  border-radius: 4px;
  top: 0;
}

.rangeslider.rangeslider-horizontal {
  background-color: #ffffff;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 75px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  border: 1px solid var(--inactive-form-label-color);
  display: inline-block;
  color: unset;
  left: 50%;
  top: 36px;
  transform: translate3d(-50%, 0, 0);
  user-select: none;
}

.rangeslider-horizontal .rangeslider__handle-tooltip::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid var(--inactive-form-label-color);
  border-top: none;
  left: 50%;
  top: -8px;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.hide {
  display: none !important;
}

.text-left {
  text-align: left;
}

.divider {
  height: 1px;
  background-color: var(--dark-section-background-color);
  margin: 0 1rem;
}

.pre-checkout-card-number {
  font-weight: 600;
  font-size: 1.5rem;
}

.pre-checkout-card-img {
  height: 3rem;
}

.pre-checkout-card-title {
  font-weight: 600;
  margin: 1rem;
  text-align: left;
}

/* guarantee modal*/

.guarantee-modal {
  color: var(--text-primary-color);
}

.guarantee-modal .production-guarantee-plans-container {
  background-color: var(--dark-section-background-color);
}

.guarantee-modal .production-guarantee-plan-container {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 1rem;
}

.guarantee-modal .production-guarantee-plan-container.chosen {
  border: 1px solid var(--brand-primary-color);
}

.guarantee-modal .production-guarantee-plan-container .price-container {
  font-size: 2rem;
  line-height: 1;
}

.stepper-step-number-svg {
  height: 1.5rem;
}

.stepper-step-number {
  font-size: 0.8rem;
}

.stepper-step-title {
  white-space: nowrap;
  font-size: 0.65rem;
  line-height: 1;
}

.stepper-separator {
  width: 100%;
  height: 0.0625rem;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='1' viewBox='0 0 60 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='9.23334e-08' y1='0.5' x2='60' y2='0.50001' stroke='%23A9A9A9' stroke-dasharray='3 3'/%3E%3C/svg%3E");
}

@media (min-width: 576px) {
  .stepper-step-number-svg {
    height: 1rem;
  }

  .stepper-step-number {
    font-size: 0.65rem;
  }
}

@media (min-width: 768px) {
  .stepper-step-title {
    font-size: 0.8rem;
  }

  .stepper-step-number-svg {
    height: 1.2rem;
  }
}

.toggle-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-checkbox-label {
  cursor: pointer;
  width: 2.5rem;
  height: 0.85rem;
  background: var(--dark-section-background-color);
  border-radius: 0.25rem;
  position: relative;
  transition: background-color 100ms;
}

.toggle-checkbox-label .toggle-checkbox-button {
  content: "";
  position: absolute;
  left: 0rem;
  width: 1.25rem;
  height: 0.85rem;
  border-radius: 0.25rem;
  transition: 100ms;
  background: var(--brand-darkest-secondary-color);
}

.toggle-checkbox:checked + .toggle-checkbox-label .toggle-checkbox-button {
  left: 100%;
  transform: translateX(-100%);
  background: var(--brand-primary-color);
}
